@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply bg-white;
}

button {
  @apply focus:ring-0;
}

body {
  @apply text-slate-700;
}
