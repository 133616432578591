.video-player {
  --media-control-background: theme('colors.video.background');
  --media-control-hover-background: theme('colors.video.backgroundHover');

  --media-range-bar-color: theme('colors.slate.50');
  --media-progress-buffered-color: theme('colors.slate.700');
  --media-time-buffered-color: theme('colors.slate.700');

  --media-range-thumb-transition: transform 0.1s linear;
  --media-range-thumb-transform: scale(0) translate(0%, 0%);
}

.video-player media-time-display {
  user-select: none;
  outline: none;
  cursor: default;
}
